<template>
  <div class="boxapp">
    <div class="header">
      <div class="whhh">
        <div class="header_img">
          <img
            class="header_imgs"
            @click="dialogVisible = true"
            src="../../assets/lianxiwomen_banner.png"
            alt=""
          />
        </div>
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="box">
      <div class="w">
        <div class="item">
          <div class="title">联系我们</div>
          <div class="tab">
            <div class="items">
              <img src="../../assets/peixunzixun_icon.png" alt="" />
              <div style="margin-left: 84px">
                <div class="item_1">企业培训咨询</div>
                <div class="item_2">了解企业培训相关政策、流程等</div>
                <div class="item_3" @click="popup">在线咨询</div>
              </div>
            </div>
            <div class="items">
              <img src="../../assets/peixunkecheng_icon.png" alt="" />

              <div style="margin-left: 84px">
                <div class="item_1">个人培训咨询</div>
                <div class="item_2">了解个人培训相关课程、政策等</div>
                <div class="item_3" @click="popup">在线咨询</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item1">
          <div class="title">在线客服</div>
          <div class="content">
            <div style="margin-left: 44px; margin-right: 118px">
              <img src="../../assets/keifu11.jpg" alt="" />
              <div style="text-align: center; font-size: 16px">
                售前服务二维码
              </div>
            </div>
            <div>
              <img src="../../assets/kefu_erweima_imgs.jpg" alt="" />
              <div style="text-align: center; font-size: 16px">
                售后服务二维码
              </div>
            </div>
            <div>
              <ul class="ul">
                <li class="li_1">微信扫码添加客服，获得更多支持</li>
                <li class="li_2">售前服务热线：400-100-2925</li>
                <li class="li_3">联系时间：周一至周五 上午9:00到下午18:00</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="ww">
          <div class="item2">
            <div class="title">售后服务</div>
            <div class="js">
              在平台使用或课程学习过程中遇到问题，请与我们联系
            </div>
            <div class="tab">
              <div>
                <div class="item2_1">售后服务热线：400-000-0000</div>
                <div class="item2_2">周一至周五 9:30-18:00</div>
              </div>
              <div>
                <div class="item2_1">售后服务热线：400-000-0000</div>
                <div class="item2_2">周一至周五 9:30-18:00</div>
              </div>
            </div>
            <div class="em">服务邮箱：services@zpton.com</div>
          </div>
        </div> -->
        <div class="ww">
          <div class="item3">
            <div class="title">联系地址</div>
            <div class="site">地址：上海市浦东新区龙东大道3000号 1号楼 5楼</div>
            <div class="map" id="allmap">
              <baidu-map
                class="map"
                :center="map.center"
                :zoom="map.zoom"
                @ready="handler"
              >
                <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
                <bm-geolocation
                  anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                  :showAddressBar="true"
                  :autoLocation="true"
                ></bm-geolocation>
                <bm-marker
                  :title="map.title"
                  :position="map.center"
                  :dragging="map.dragging"
                  animation="BMAP_ANIMATION_DROP"
                >
                </bm-marker>
              </baidu-map>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 20px; font-size: 16px">
        400-100-2925
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      title: "上海市浦东新区龙东大道3000号 1号楼 5楼",
      show: false,
      map: {
        title: "上海市浦东新区龙东大道3000号 1号楼 5楼",
        center: { lng: 121.639944, lat: 31.228719 },
        zoom: 15,
        show: true,
        dragging: true,
      },
    };
  },
  methods: {
    popup() {
      window.location.href =
        "https://www.zpton.com/allcourses?type=1&name=%E8%99%8E%E5%B9%B4%E5%B0%B1%E5%9C%B0%E8%BF%87%C2%B7%E6%8A%80%E8%83%BD%E5%85%8D%E8%B4%B9%E5%AD%A6&id=234&is_type=2";
    },
    handler({ BMap, map }) {
      let me = this;

      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener("click", function (e) {});
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.boxapp {
  overflow: hidden;
  position: relative;
}
.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 74px;
}

.box {
  background: url("../../assets/beijingtu.png");
}
.item {
  overflow: hidden;
  .title {
    margin-top: 95px;
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .tab {
    margin-top: 62px;
    display: flex;
    justify-content: space-between;
    .items {
      display: flex;
      padding: 69px;
      box-sizing: border-box;
      width: 580px;
      height: 282px;
      border: 2px solid #f1f1f1;
      img {
        margin-top: 30px;
        width: 58px;
        height: 46px;
      }
      .item_1 {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        margin-bottom: 12px;
      }
      .item_2 {
        font-size: 16px;
        margin-bottom: 12px;
      }
      .item_3 {
        cursor: pointer;
        width: 99px;
        height: 45px;
        background: #1276fb;
        color: #fff;
        text-align: center;
        line-height: 45px;
      }
    }
  }
}
.item1 {
  margin-top: 81px;

  .title {
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    margin-bottom: 56px;
  }
  .content {
    display: flex;
    img {
      width: 203px;
      height: 195px;
    }
    .ul {
      margin-top: 30px;
      margin-left: 84px;
      .li_1 {
        margin-bottom: 29px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .li_2 {
        margin-bottom: 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .li_3 {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
  }
}
.item2 {
  .title {
    margin-top: 110px;
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .js {
    margin-top: 34px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
  .tab {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    .item2_1 {
      margin-bottom: 15px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .item2_2 {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
  }
  .em {
    margin-top: 36px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
}
.item3 {
  .title {
    margin-top: 81px;
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .site {
    margin-top: 24px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
  .map {
    margin: 66px 0 76px 0;
    // width: 1005px;
    height: 536px;
    line-height: 436px;
    text-align: center;
    background: #d8d8d8;
  }
}
</style>
